<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Mensaje</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-6">
            <label class="label">Nombre</label>
            <p class="paragraph">{{localName}}</p>
          </div>
          <div class="column is-6">
            <label class="label">Correo electrónico</label>
            <p class="paragraph">{{localEmail}}</p>
          </div>
          <div class="column is-6">
            <label class="label">Fecha</label>
            <p class="paragraph">{{localDate}}</p>
          </div>
          <div class="column is-6">
            <label class="label">{{ currentTab === isPublications ? 'Publicación' : 'Producto'}}</label>
            <p class="paragraph">{{origin}}</p>
          </div>
          <div class="column is-6 is-flex is-align-items-center">
            <label class="label mr-5 mb-0">Estatus</label>
            <span>
              <input
                id="switchStatus"
                type="checkbox"
                name="switchStatusStatus"
                class="switch is-rounded is-small"
                v-model="localStatus"
                :checked="localStatus ? 'checked' : ''"
                @change="$emit('update-status-modal', idMessage)"
              >
              <label for="switchStatus"></label>
            </span>
          </div>
          <div class="column is-12">
            <label class="label">Mensaje</label>
            <p class="paragraph">{{localMessage}}</p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="$emit('close-modal')"
        >
          CERRAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalMessage',
  data () {
    return {
      idMessage: this.currentDataModal.id,
      localName: this.currentDataModal.user_name,
      localEmail: this.currentDataModal.email,
      localDate: this.currentDataModal.created_at,
      origin: this.currentDataModal.publication_name || this.currentDataModal.product_name,
      localStatus: this.currentDataModal.active,
      localMessage: this.currentDataModal.commentary
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    titleModal: {
      type: String,
      default: 'Pregunta'
    },
    currentTab: {
      type: Number,
      default: null
    },
    isPublications: {
      type: Number,
      default: 1
    },
    isProduct: {
      type: Number,
      default: 2
    },
    currentDataModal: {
      default: null
    }
  },
  methods: {
    resetStatus () {
      this.localStatus = this.currentDataModal.active
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
